export type Data = {
  id: number;
  title: string;
  text: string[];
  link: string;
};

export const NewsData: Data[] = [
  {
    id: 0,
    title: "Popusti u listopadu",
    text: [
      "- 10% na tretman kolagen stimulatorom Radiesse",
      "- 15% na tretman krvnom plazmom (PRP) za lice ili vlasište",
      "- 20 % na tretman kolagen stimulatorom Radiesse + tretman krvnom plazmom (PRP) za lice ili vlasište",
    ],
    link: "/akcijeinovosti",
  },
  {
    id: 1,
    title: "Novo u ponudi",
    text: [
      "Pbserum sadrzi kombinaciju enzima lipaze, liaze i kolagenaze te s njim možemo izvrsno djelovati na problematiku lokaliziranih masnih naslaga, celulita i ožiljaka. Kao takav je idealan za tretman podbratka, trbuha te lica. Ukoliko želite znati jeste li baš vi kandidat za navedene tretmane, obratite nam se s povjerenjem!",
    ],
    link: "/akcijeinovosti",
  },
];
