interface Data {
  date: string;
  title: string;
  image: string;
  subtitle?: string;
  contact?: string;
  description: string[];
}

export const AkcijeData: Data[] = [
  {
    date: "1.10.2024.",
    title: "Popusti u listopadu!",
    image: "test.png",
    description: [
      "- 10% na tretman kolagen stimulatorom Radiesse",
      "- 15% na tretman krvnom plazmom (PRP) za lice ili vlasište",
      "- 20 % na tretman kolagen stimulatorom Radiesse + tretman krvnom plazmom (PRP) za lice ili vlasište",
      "Rezervirajte svoj termin već danas!",
    ],
  },
  {
    date: "1.7.2024.",
    title: "Popusti u srpnju!",
    image: "test.png",
    description: [
      "15% popusta na:",
      "- Bocouture 100 jedinica",
      "- NCTF 135 (paket od tri tretmana)",
      "- Pb serum (low, medium)",
      "Rezervirajte svoj termin vec danas!",
    ],
  },
  {
    date: "1.6.2024.",
    title: "Popusti u lipnju!",
    image: "test.png",
    description: [
      "- 10% popusta na tretman sa 100 IJ botoxa koliko je potrebno za značajno smanjenje znojenja u regiji pazuha, dlanova ili stopala.",
      "- 10% popusta na Belotero Intense dermalni filer ukoliko zelite volumizirati usne, ublaziti nazolabijalne bore ili suptilno konturirati lice.",
      "Rezervirajte svoj termin vec danas!",
    ],
  },
];
