interface Data {
  name: string;
  spec: string;
}

export const TimData: Data[] = [
  {
    name: "GORAN RADOVNIKOVIĆ, dr.med.",
    spec: "specijalist opće interne medicine, subspecijalist endokrinologije i dijabetologije",
  },
  {
    name: " SILVIJA RADOČAJ PERKO, dr.med.",
    spec: "specijalist oftalmologije",
  },
  {
    name: " JULKA MARKOVIĆ POSAVEC, dr.med.",
    spec: "specijalist medicine rada ",
  },
  {
    name: "IRENA DOMIĆ, dr.med.",
    spec: "specijalist medicine rada i sporta",
  },
  {
    name: "doc. prim. dr.sc. ZLATKO KLJAJIĆ, dr.med.",
    spec: "specijalist ORL, Merz Key Opinion Leader (KOL), subspecijalist audiologije",
  },
  {
    name: "ZDESLAVA UDOVIČIĆ ",
    spec: "profesor psihologije",
  },
  {
    name: "BRIGITA ERCEGOVIĆ, dr.med.",
    spec: "specijalist dermatologije i venerologije",
  },
  {
    name: "ŽELJKA ERCEGOVIĆ, dr.med.",
    spec: "specijalist medicine rada ",
  },
  {
    name: "IVANA TOMASOVIĆ dr.med.",
    spec: "specijalist medicine rada i sporta ",
  },
];
